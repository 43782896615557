@import 'variables';
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  font-size: 12px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 300;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
#root>div:first-child {
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg.stroke-1 path {
  stroke-width: 1;
}